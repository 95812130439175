<template>
  <div class="survey">
    <b-form>
      <b-form-group label-cols="3" :label="title">
        <b-row class="form-inner-row">
          <b-col class="form-inner-col">
            <b-form-radio-group v-model="q.answer[0].data">
              <b-form-radio value="규칙적 (3끼)">규칙적 (3끼)</b-form-radio>
              <b-form-radio value="불규칙적 (1~2끼)">불규칙적 (1~2끼)</b-form-radio>
            </b-form-radio-group>
          </b-col>
        </b-row>
        <b-row class="form-inner-row">
          <b-col class="form-inner-col">
            <label>{{ q.answer[1].label }}</label>
            <b-form-input type="text" v-model="q.answer[1].data" />
          </b-col>
        </b-row>
      </b-form-group>
    </b-form>
  </div>
</template>
<script>
export default {
  name: 'Q30',
  model: {
    prop: 'q',
  },
  props: ['q'],
  data() {
    return {
      title: '식사 습관',
    };
  },
  updated() {
    if (this.q === '') {
      this.q = {
        title: '식사 습관',
        answer: [
          {
            data: '',
          },
          {
            label: '식사시 밥 양 (공기)',
            data: '',
          },
        ],
      };
    } 
  },
};
</script>